import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

type DividerDrectionType = 'horizontal' | 'vertical';

@Component({
  selector: 'app-divider',
  template: `
    <ng-container *ngIf="direction === 'horizontal'">
      <div
        class="border-t border-slate-300 w-full my-3"
        [ngClass]="{ 'border-dashed': type === 'dashed', 'border-dotted': type === 'dotted' }"></div>
    </ng-container>
    <ng-container *ngIf="direction === 'vertical'">
      <div class="border-r border-slate-300  mx-3 inline-block align-middle" [ngStyle]="{ height: height }"></div>
    </ng-container>
  `,
  styles: [],
  standalone: true,
  imports: [CommonModule],
})
export class DividerComponent implements OnInit {
  @Input() height: string = '1rem';
  @Input() direction: DividerDrectionType = 'horizontal';
  @Input() type: 'solid' | 'dashed' | 'dotted' = 'solid';
  constructor() {}

  ngOnInit(): void {}
}
