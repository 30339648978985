import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from 'src/common/services/http-interceptor.service';

import { TuiRoot, TuiAlert, TuiIcon, TuiLink, TuiDialog, TuiButton } from '@taiga-ui/core';
import { CommonAlertService } from 'src/common/services/common-alert.service';
import { CommonDialogService } from 'src/common/services/common-dialog.service';
import { LoginService } from './portal/login/login.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const TUI_MODULES = [TuiRoot, TuiDialog, TuiAlert, TuiButton, TuiLink, TuiIcon];

@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ...TUI_MODULES,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    CommonAlertService,
    CommonDialogService,
    LoginService,
    NG_EVENT_PLUGINS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private _translate: TranslateService) {
    const localLang = localStorage.getItem('lang');
    let browserLang = _translate.getBrowserLang();
    if (browserLang !== 'en' && browserLang !== 'zh') {
      browserLang = '';
    }
    const lang = localLang || browserLang || 'en';
    this._translate.setDefaultLang(lang);
  }
}
