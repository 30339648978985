import { Component, OnInit } from '@angular/core';
import { DividerComponent } from 'src/app/components/divider/divider.component';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="flex flex-column justify-center h-screen text-lg secondary-text">
      <div class="flex justify-center items-center">
        <h1 class="m-0">404</h1>
        <app-divider direction="vertical"></app-divider>
        <p class="m-0">This page could not be found.</p>
      </div>
    </div>
  `,
  styles: [],
  standalone: true,
  imports: [DividerComponent],
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
