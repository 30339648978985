import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService } from '@taiga-ui/core';

interface AlertOptions {
  label?: string;
  status: string;
  autoClose?: boolean | number;
  hasIcon?: boolean;
}

@Injectable()
export class CommonAlertService {
  constructor(
    private _alert: TuiAlertService,
    private _translate: TranslateService
  ) {}

  open(message: string, options?: AlertOptions) {
    const _options: AlertOptions = {
      label: options?.label || options?.status === 'success' ? 'Success' : options?.status === 'error' ? 'Error' : 'Info',
      status: 'error',
      autoClose: true,
      hasIcon: true,
      ...options,
    };
    this._alert.open(this._translate.instant(message)).subscribe();
    // this._alert.open(message, _options).subscribe();

    // this._alert.open(message, _options).subscribe();
  }
}
