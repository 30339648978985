<div class="flex flex-col justify-center h-screen">
  <div class="text-center mt-8 pt-5 f">
    <div class="mb-2 lex flex-col items-center">
      <span class="text-6xl secondary-text text-white px-4 font-bold antialiased title-bg">WebP Cloud Services</span>
      <app-divider></app-divider>
      <p class="text-4xl ml-2 font-extralight text-slate-500">
        <tui-icon icon="@tui.activity" class="text-lg"></tui-icon>
        P R E L U D E
      </p>
    </div>
  </div>
  <div class="text-center h-1/2">
    <div class="mt-2 mb-4 text-base text-slate-500">
      By completing this registration, you hereby agree to our
      <a href="https://webp.se/privacy-policy/" target="_blank" tuiLink [pseudo]="true">privacy policy</a>
    </div>
    <button tuiButton appearance="secondary" [loading]="isNavigating" (click)="loginWithGitHub()">
      <img src="assets/images/github.png" width="20px" alt="github-mark" class="mr-3" loading="lazy" />
      <span> Login with GitHub </span>
    </button>
    <hr />
    <br />
    <button tuiButton appearance="secondary" [loading]="isNavigating" (click)="loginWithPasskey()">
      <img src="assets/images/passkey.png" width="20px" alt="passkey-mark" class="mr-3" loading="lazy" />
      <span> Login with Passkey </span>
    </button>
    <button (click)="test()">test get</button>
  </div>
</div>
