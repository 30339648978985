import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext, TuiIcon } from '@taiga-ui/core';
import { CommonDialogModel } from 'src/common/types/common.interface';
import { CommonModule } from '@angular/common';
import { DividerComponent } from '../divider/divider.component';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonLoading } from '@taiga-ui/kit';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, TuiButton, TuiButtonLoading, TuiIcon, DividerComponent, TranslateModule],
})
export class DialogComponent implements OnInit {
  @Input() config: Partial<CommonDialogModel> = {};

  constructor(@Inject(POLYMORPHEUS_CONTEXT) private context: TuiDialogContext<boolean>) {}

  isLoading = false;

  get data(): any {
    return this.context.data;
  }

  ngOnInit(): void {
    if (this.data) {
      this.config = this.data;
    }
  }

  async ok() {
    if (this.config.handleOk) {
      this.isLoading = true;
      const res = await this.config.handleOk();
      this.isLoading = false;
      if (res) {
        this.context.completeWith(true);
      }
      return;
    }
    this.context.completeWith(true);
  }

  async cancel() {
    if (this.config.handleCancel) {
      const res = await this.config.handleCancel();
      if (res) {
        this.context.completeWith(false);
      }
      return;
    }
    this.context.completeWith(false);
  }
}
