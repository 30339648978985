import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationResponseJSON } from '@passwordless-id/webauthn/dist/esm/types';
import { ResponseModel } from 'src/common/types/common.interface';

@Injectable()
export class LoginService {
  constructor(private _http: HttpClient) {}

  loginCommonUrl = '/login/passkey';

  beginLogin() {
    return this._http.post<ResponseModel<string>>(`${this.loginCommonUrl}/begin_login`, {});
  }

  finishLogin(challenge: any, payload: AuthenticationResponseJSON) {
    return this._http.post<ResponseModel<string>>(`${this.loginCommonUrl}/finish_login?challenge=${challenge}`, payload);
  }
  proxyCommonUrl = '/v1/proxy';

  getProxies() {
    return this._http.get<any>(this.proxyCommonUrl);
  }
}
