import { HttpInterceptor, HttpErrorResponse, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../types/common.interface';
import { Router } from '@angular/router';
import { CommonAlertService } from './common-alert.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _alertService: CommonAlertService
  ) {}

  errorNotify(message: string) {
    this._alertService.open(message, { status: 'error' });
  }

  handle401Error(error: HttpErrorResponse) {
    this.errorNotify(error.error.messages);
    this._router.navigate(['/login']);
    localStorage.clear();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<ResponseModel>> {
    const token = localStorage.getItem('token');
    let authReq = req;
    if (!req.url.includes('/assets/i18n/')) {
      authReq = req.clone({
        url: `${environment.API_URL}${req.url}`,
        withCredentials: true,
        // setHeaders: {
        //   token: token ? token : '',
        // },
      });
    }
    const cookies = req.headers.get('Cookie');
    console.log('Cookies in request:', cookies);

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.handle401Error(error);
            break;
          default:
            if (error.error.messages) {
              this.errorNotify(error.error.messages);
            }
            break;
        }
        return throwError(() => error);
      })
    );
  }
}
