import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { client } from '@passwordless-id/webauthn';
import { LoginService } from './login.service';
import { CommonModule } from '@angular/common';
import { DividerComponent } from 'src/app/components/divider/divider.component';
import { TuiButton, TuiIcon, TuiLink } from '@taiga-ui/core';
import { TuiButtonLoading } from '@taiga-ui/kit';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [DividerComponent, TuiIcon, TuiButton, TuiButtonLoading, TuiLink],
})
export class LoginComponent implements OnInit {
  constructor(
    private _router: Router,
    private _service: LoginService
  ) {}

  isNavigating = false;

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this._router.navigate(['/proxy']);
    }
  }

  loginWithGitHub() {
    this.isNavigating = true;
    window.location.href = `${environment.API_URL}/login/github`;
  }

  async loginWithPasskey() {
    const { challenge } = await lastValueFrom(this._service.beginLogin());
    const authentication = await client.authenticate({
      challenge: challenge,
      timeout: 60000,
    });

    this._service.finishLogin(challenge, authentication).subscribe(({ token, success }) => {
      if (success) {
        localStorage.setItem('token', token);
        this._router.navigate(['/proxy']);
      }
    });
  }

  test() {
    this._service.getProxies().subscribe((res) => {
      console.log(res);
    });
  }
}
