import { Injectable, Injector, TemplateRef } from '@angular/core';
import { TuiDialogOptions, TuiDialogService } from '@taiga-ui/core';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { CommonDialogModel } from '../types/common.interface';
import { Observable } from 'rxjs';

@Injectable()
export class CommonDialogService {
  constructor(
    private _dialog: TuiDialogService,
    private injector: Injector
  ) {}

  open(params: Partial<CommonDialogModel>, config: Partial<TuiDialogOptions<any>> = {}): Observable<boolean> {
    return this._dialog.open(new PolymorpheusComponent(DialogComponent, this.injector), {
      data: params,
      ...config,
    });
  }
}
