<div>
  <div class="flex items-center">
    <div class="text-lg font-bold">{{ config.title ?? '' | translate }}</div>
    <ng-container *ngIf="config.subtitlePosition === 'right'">
      <div class="secondary-text text-sm px-2 ml-4 rounded">{{ config.subtitle ?? '' | translate }}</div>
    </ng-container>
  </div>
  <app-divider></app-divider>
  <ng-container *ngIf="config.subtitlePosition === 'bottom'">
    <div class="secondary-text text-sm bottom-subtitle">{{ config.subtitle ?? '' | translate }}</div>
  </ng-container>
  <div class="my-2 flex">
    <ng-container *ngIf="config.hasIcon">
      <div class="mr-2" [ngSwitch]="config.iconType">
        <tui-icon *ngSwitchCase="'success'" class="text-emerald-500" icon="@tui.circle-check"></tui-icon>
        <tui-icon *ngSwitchCase="'error'" class="text-red-500" icon="@tui.circle-x"></tui-icon>
        <tui-icon *ngSwitchCase="'warning'" class="text-amber-500" icon="@tui.circle-alert"></tui-icon>
        <tui-icon *ngSwitchCase="'question'" class="text-blue-500" icon="@tui.circle-help"></tui-icon>
        <tui-icon *ngSwitchDefault class="text-stone-500" icon="@tui.info"></tui-icon>
      </div>
    </ng-container>
    <div *ngIf="config.content" [innerHTML]="config.content"></div>
    <div *ngIf="config.templateContent" class="w-full">
      <ng-template [ngTemplateOutlet]="config.templateContent"></ng-template>
    </div>
  </div>
  <div *ngIf="config.showButton" class="text-right mt-2">
    <button tuiButton appearance="whiteblock" size="m" (click)="cancel()" class="mr-2">
      {{ config.buttonCancelText || 'cancel' | translate }}
    </button>

    <button tuiButton appearance="secondary" size="m" [loading]="isLoading" (click)="ok()">
      {{ config.buttonOkText || 'confirm' | translate }}
    </button>
  </div>
</div>
